import { Box } from '@mui/material';
import { bool, func, object, oneOf, shape, string } from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import config from '../../config';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import routeConfiguration from '../../routing/routeConfiguration';
import { convertMoneyToNumber, formatMoney } from '../../util/currency';
import { ensureListing, ensureOwnListing, ensureUser, userDisplayNameAsString } from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { types as sdkTypes } from '../../util/sdkLoader';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import { LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT, createSlug } from '../../util/urlHelpers';

import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  NamedRedirect,
  Page,
  UniversalButton,
} from '../../components';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import TopbarContainer from '../TopbarContainer/TopbarContainer';

import {
  createConversation,
  fetchSimilarListings,
  fetchTransactionLineItems,
  sendEnquiry,
  setInitialValues,
  updateListingPagePreviousPath,
} from './ListingPage.duck';
import SectionBasicInfo from './SectionBasicInformation';
import SectionGallery from './SectionGallery';
import SectionHeading from './SectionHeading';
import SectionShoppingDetails from './SectionShoppingDetails/SectionShoppingDetails.js';

import Loader from '../../components/Loader/Loader';
import Spacer from '../../components/Spacer/Spacer.js';
import TopBarWithClose from '../../components/TopBarWithClose/TopBarWithClose';
import { useBuyFlowContext } from '../../contexts/buy/BuyFlowContext.jsx';
import { addCurrentUserFavoriteListing, removeCurrentUserFavoriteListing } from '../../ducks/user.duck';
import { useApi, useApiCallback } from '../../hooks/useApi.js';
import SectionRecentItems from '../LandingPage/SectionRecentItems/SectionRecentItems.js';
import css from './ListingPage.module.css';
import SectionOfferedBy from './SectionOfferedBy';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  }
  if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

export const ListingPageComponent = ({
  params,
  currentUser,
  getListing,
  getOwnListing,
  intl,
  location,
  history,
  scrollingDisabled,
  showListingError,
  customConfig,
  onLocaleChange,
  currentLocale,
  favoriteListings,
  addUserFavorite,
  removeUserFavorite,
  isAuthenticated,
  previousPath,
  updatePreviousPath,
  callSetInitialValues,
  onCreateConversation,
  fetchSimilarListingsData,
  similarListings,
}) => {
  const listingId = new UUID(params.id);
  const isPendingApprovalVariant = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = params.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = params.slug || createSlug(currentListing.attributes.title || '');
  const isApproved = currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;
  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  const createOrderCb = useApiCallback((api, args) => api.createOrder(args));
  const itemAvailability = useApi(
    api => (isAuthenticated ? api.checkItemAvailability(params.id) : Promise.resolve({ data: { isAvailable: true } })),
    [isAuthenticated]
  );
  const buyFlow = useBuyFlowContext();

  const availableForPurchase = itemAvailability.data?.isAvailable;

  useEffect(() => {
    fetchSimilarListingsData(
      currentListing?.id?.uuid,
      currentListing?.attributes?.publicData?.listingType,
      currentListing?.attributes?.publicData?.category
    );
  }, [currentListing?.id?.uuid]);

  useEffect(() => {
    if (history.location?.state?.prevPath === undefined || history.location?.state?.prevPath === null) {
      return;
    }

    const { prevPath, search } = history.location.state;
    updatePreviousPath(`${prevPath || ''}${search || ''}`);
  }, [history.location.state]);

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) && showListingError && showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return (
      <NamedRedirect name="ListingPageWithReturn" params={{ slug: listingSlug, ...params }} search={location.search} />
    );
  }

  const { description = undefined, price = null, title = '', publicData } = currentListing.attributes;
  const { siteTitle } = config;
  const schemaTitle = intl.formatMessage({ id: 'listing_page.schema_title' }, { title, siteTitle });
  const itemTitle = richText(title, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
    longWordClass: css.longWord,
  });
  const richTitle = <h1 className={css.richTitle}>{itemTitle}</h1>;

  if (showListingError && showListingError.status === 404) {
    return <NotFoundPage />;
  }

  if (showListingError) {
    return (
      <Page title={schemaTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>
            <TopbarContainer onLocaleChange={onLocaleChange} currentLocale={currentLocale} />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <p className={css.errorText}>
              <FormattedMessage id="listing_page.error_loading_listing" />
            </p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
  if (!currentListing.id) {
    // Still loading the listing

    return (
      <Page title={schemaTitle} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>
            <TopbarContainer onLocaleChange={onLocaleChange} currentLocale={currentLocale} />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.loaderWrapper}>
              <Loader />
            </div>
          </LayoutWrapperMain>
        </LayoutSingleColumn>
      </Page>
    );
  }

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing = userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const facebookImages = listingImages(currentListing, 'facebook');
  const schemaImages = listingImages(currentListing, `${config.listing.variantPrefix}-2x`).map(img => img.url);

  const host = typeof window !== 'undefined' && window.location && window.location.host;
  const productURL = `${host}${location.pathname}${location.search}${location.hash}`;
  const brand = currentListing?.attributes?.publicData?.brand;
  const brandMaybe = brand ? { brand: { '@type': 'Brand', name: brand } } : {};
  const schemaPriceNumber = convertMoneyToNumber(price);

  const authorLink = currentListing.author?.id?.uuid ? (
    <NamedLink className={css.authorNameLink} name="ProfilePage" params={{ id: currentListing.author.id.uuid }}>
      {authorDisplayName.replace(' .', '')}
    </NamedLink>
  ) : null;

  const categoryOptions = findOptionsForSelectFilter('category', customConfig.filters);
  const category =
    publicData && publicData.category ? (
      <span>
        {categoryLabel(categoryOptions, publicData.category)}
        <span className={css.separator}>•</span>
      </span>
    ) : null;

  const listingType = publicData?.listingType;
  const listingCategory = publicData?.category;
  const isSold = publicData?.sold;
  const isRefurbished = publicData?.refurbished === 'yes';
  const handleViewMore = () => {
    history.push(
      createResourceLocatorString('SearchPage', routeConfiguration(), { type: listingType, category: listingCategory })
    );
  };

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      contentType="website"
      description={description}
      facebookImages={facebookImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description,
        name: schemaTitle,
        image: schemaImages,
        ...brandMaybe,
        offers: {
          '@type': 'Offer',
          url: productURL,
          priceCurrency: price.currency,
          price: schemaPriceNumber,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot}>
        <LayoutWrapperTopbar>
          <div className={css.mobileTopbar}>
            <TopBarWithClose
              onCloseClick={() => history.push(previousPath)}
              itemTitle={itemTitle}
              addUserFavorite={addUserFavorite}
              removeUserFavorite={removeUserFavorite}
              isLiked={favoriteListings.includes(currentListing?.id?.uuid)}
              listingId={currentListing?.id?.uuid}
              listingType={listingType}
              isOwnListing={isOwnListing}
              currentUserId={currentUser?.id?.uuid}
            />
          </div>
          <div className={css.desktopTopbar}>
            <TopbarContainer onLocaleChange={onLocaleChange} currentLocale={currentLocale} />
          </div>
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          {!!createOrderCb.error && (
            <Box mx="auto" mb={{ xs: -2, md: -4 }} textAlign="center" color="error.main">
              <p className={css.errorText}>
                <FormattedMessage id="listing_page.error_creating_order" />
              </p>
            </Box>
          )}
          <div className={css.contentWrapperForProductLayout}>
            <SectionGallery listing={currentListing} sold={isSold} refurbished={isRefurbished} />
            <div className={css.mainColumnForProductLayout}>
              <SectionHeading
                priceTitle={priceTitle}
                formattedPrice={formattedPrice}
                richTitle={richTitle}
                category={category}
                onBuyNow={onBuyNow}
                buyLoading={createOrderCb.loading}
                isOwnListing={isOwnListing}
                intl={intl}
                listing={currentListing}
                addUserFavorite={addUserFavorite}
                listingType={listingType}
                removeUserFavorite={removeUserFavorite}
                isLiked={favoriteListings.includes(currentListing?.id?.uuid)}
                listingId={currentListing?.id?.uuid}
                currentUserId={currentUser?.id?.uuid}
                availableForPurchase={availableForPurchase}
                refurbished={isRefurbished}
              />
              <SectionBasicInfo
                description={description}
                listingTitle={richTitle}
                filters={customConfig.filters}
                publicData={publicData}
                intl={intl}
              />
              <SectionOfferedBy
                onContactUser={onContactUser}
                author={ensuredAuthor}
                listing={currentListing}
                authorLink={authorLink}
                isAuthenticated={isAuthenticated}
                isOwnListing={isOwnListing}
              />
              <div className={css.payAtGearroCardWrapper}>
                <p className={css.payAtGearroCardText}>{intl.formatMessage({ id: 'listing_page.pay_at_gearro' })}</p>
              </div>
              <Spacer size={24} />
              <SectionShoppingDetails description={description} />
            </div>
          </div>
          {similarListings && similarListings.length > 0 && (
            <div className={css.similarItemsContainerWrapper}>
              <div className={css.similarItemsContainer}>
                <div className={css.similarItemsHeader}>
                  <h2 className={css.similarItemsContainerTitle}>
                    {intl.formatMessage({ id: 'listing_page.similar_items_label' })}
                  </h2>
                  <UniversalButton type="secondary" onClick={handleViewMore}>
                    {intl.formatMessage({ id: 'listing_page.view_more' })}
                  </UniversalButton>
                </div>
                <SectionRecentItems
                  recentListings={similarListings}
                  intl={intl}
                  likedListingsIds={favoriteListings}
                  addUserFavorite={addUserFavorite}
                  removeUserFavorite={removeUserFavorite}
                  currentUserId={currentUser?.id?.uuid}
                />
              </div>
            </div>
          )}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );

  function onContactUser(author, listing) {
    if (!currentUser) {
      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), {
        from: `${location.pathname}${location.search}${location.hash}`,
      });
    } else {
      onCreateConversation(currentUser, author, listing)
        .then(conversationId => {
          history.push(createResourceLocatorString('InboxPageTab', routeConfiguration(), { tab: conversationId }, {}), {
            prevPath: history.location.pathname,
          });
        })
        .catch(() => {});
    }
  }

  function listingImages(listing, variantName) {
    return (listing.images || [])
      .map(image => {
        const { variants } = image.attributes;
        const variant = variants ? variants[variantName] : null;

        const { sizes } = image.attributes;
        const size = sizes ? sizes.find(i => i.name === variantName) : null;

        return variant || size;
      })
      .filter(variant => variant != null);
  }

  async function onBuyNow() {
    if (!currentUser) {
      return history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), {
        from: `${location.pathname}${location.search}${location.hash}`,
      });
    }

    const result = await createOrderCb.execute({
      buyerId: currentUser.id.uuid,
      sellerId: ensuredAuthor.id.uuid,
      listingId: params.id,
    });

    if (result.data.order) {
      buyFlow.dispatch({ type: 'CREATE_ORDER', payload: result.data.order });
      const orderPath = pathByRouteName('ListingOrderPage', routeConfiguration(), params);
      history.push(orderPath);
    }
  }
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  showListingError: null,
  customConfig: config.custom,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  scrollingDisabled: bool.isRequired,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  onCreateConversation: func.isRequired,
  customConfig: object,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    createConversationInProgress,
    createConversationError,
    previousPath,
    suggestedListingIds,
  } = state.ListingPage;

  const { currentUser, currentUserFavoriteListingsIds } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const similarListings = getListingsById(state, suggestedListingIds);

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    createConversationInProgress,
    createConversationError,
    favoriteListings: currentUserFavoriteListingsIds,
    previousPath,
    similarListings,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (orderData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(orderData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onCreateConversation: (currentUser, seller, item) => dispatch(createConversation(currentUser, seller, item)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  addUserFavorite: (listingId, listingType) => dispatch(addCurrentUserFavoriteListing(listingId, listingType)),
  removeUserFavorite: listingId => dispatch(removeCurrentUserFavoriteListing(listingId)),
  updatePreviousPath: path => dispatch(updateListingPagePreviousPath(path)),
  fetchSimilarListingsData: (currentListingId, listingType, listingCategory) =>
    dispatch(fetchSimilarListings(currentListingId, listingType, listingCategory)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), injectIntl)(ListingPageComponent);

export default ListingPage;
