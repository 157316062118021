import PropTypes from 'prop-types';
import React, { useState } from 'react';

import classNames from 'classnames';
import { IconArrowHead, SoldRefurbishedItemImageWrapper } from '../../../components';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';

import css from './ListingImageGallery.module.css';

import ImagePreview from '../../../components/ImagePreview/ImagePreview';
import Spacer from '../../../components/Spacer/Spacer';

function ListingImageGallery(props) {
  const { images, rootClassName, sold, refurbished } = props;

  const [isImagePreviewOpen, setIsImagePrieviewOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const itemSrcRefs = [];

  const imagesSrc = images
    .filter(image => image !== undefined)
    .map(image => image.attributes.variants['scaled-large'].url);
  const imageSrcCount = imagesSrc.length - 1;

  const setItemRef = (elem, index) => {
    itemSrcRefs[index] = elem;
  };

  const updateCurrentSlideIndex = step => {
    if (imageIndex + step < 0) {
      setImageIndex(imageSrcCount);
      itemSrcRefs[imageSrcCount].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'end',
      });
    } else if (imageIndex + step > imageSrcCount) {
      setImageIndex(0);
      itemSrcRefs[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
    } else {
      setImageIndex(imageIndex + step);
      itemSrcRefs[imageIndex + 1].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'end',
      });
    }
  };

  const handleImagePreviewOpenClick = () => {
    document.body.style.overflow = 'hidden';
    setIsImagePrieviewOpen(true);
  };

  const handleImagePreviewCloseClick = () => {
    document.body.style.overflow = 'auto';
    setIsImagePrieviewOpen(false);
  };

  return (
    <div className={!isImagePreviewOpen ? [css.root, rootClassName].join(' ') : css.rootWithPreview}>
      {isImagePreviewOpen ? (
        <ImagePreview currentIndex={imageIndex} imagesSrc={imagesSrc} onClose={() => handleImagePreviewCloseClick()} />
      ) : (
        <></>
      )}
      <div className={css.imagePreviewWrapper}>
        <div className={css.navLeft} onClick={() => updateCurrentSlideIndex(-1)}>
          <div className={css.navArrowWrapper}>
            <IconArrowHead direction="left" size="big" />
          </div>
        </div>
        <div className={css.navRight} onClick={() => updateCurrentSlideIndex(1)}>
          <div className={css.navArrowWrapper}>
            <IconArrowHead direction="right" size="big" />
          </div>
        </div>

        {imagesSrc.map((src, index) => {
          const imageClassName =
            index === imageIndex ? classNames(css.imagePreviewImage, css.selectedImage) : css.imagePreviewImage;

          return (
            <SoldRefurbishedItemImageWrapper
              key={index}
              sold={sold && index === imageIndex}
              refurbished={refurbished && index === imageIndex}
              borderRadius="4px"
            >
              <img className={imageClassName} src={src} onClick={() => handleImagePreviewOpenClick()} />
            </SoldRefurbishedItemImageWrapper>
          );
        })}
      </div>
      <Spacer size={24} />
      <div className={css.imageCarouselPreviewRoot}>
        {imagesSrc.map((src, index) => (
          <div
            key={`carousel_wrapper_${index}`}
            className={css.imageCarouselImageWrapper}
            ref={e => setItemRef(e, index)}
          >
            <SoldRefurbishedItemImageWrapper borderRadius="4px" sold={sold} refurbished={refurbished} hideLabel>
              <img
                key={`carousel_${index}`}
                className={css.imageCarouselImage}
                src={src}
                onClick={() => setImageIndex(index)}
              />
            </SoldRefurbishedItemImageWrapper>
          </div>
        ))}
      </div>
    </div>
  );
}

const { arrayOf } = PropTypes;

ListingImageGallery.propTypes = {
  images: arrayOf(propTypes.image).isRequired,
  intl: intlShape.isRequired,
  sold: PropTypes.bool,
};

export default injectIntl(ListingImageGallery);
