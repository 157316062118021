import React, { useState } from 'react';
import classNames from 'classnames';

import css from './ImagePreview.module.css';

import ModalComponent from '../ModalComponent/ModalComponent';
import RigthArrow from '../../assets/icons/right-arrow.png';
import CloseIcon from '../../assets/icons/x-12.png';

function ImagePreview(props) {
  const { currentIndex, imagesSrc, onClose } = props;

  const [imageIndex, setImageIndex] = useState(currentIndex);
  const imageSrcCount = imagesSrc.length - 1;

  const updateCurrentSlideIndex = step => {
    if (imageIndex + step < 0) {
      setImageIndex(imageSrcCount);
    } else if (imageIndex + step > imageSrcCount) {
      setImageIndex(0);
    } else {
      setImageIndex(imageIndex + step);
    }
  };

  return (
    <ModalComponent onClose={onClose} rootClassName={css.imagePreviewRoot} contentClassName={css.imagePreviewWrapper}>
      {imageSrcCount !== 0 ? (
        <>
          <div className={css.navLeft} onClick={() => updateCurrentSlideIndex(-1)}>
            <div className={css.navArrowWrapper}>
              <img className={css.prevArrowIcon} src={RigthArrow} />
            </div>
          </div>
          <div className={css.navRight} onClick={() => updateCurrentSlideIndex(1)}>
            <div className={css.navArrowWrapper}>
              <img className={css.nextArrowIcon} src={RigthArrow} />
            </div>
          </div>
          <div className={css.closeButton} onClick={onClose}>
            <div className={css.closeButtonWrapper}>
              <img className={css.closeButtonIcon} src={CloseIcon} />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {imagesSrc.map((src, index) => {
        const imageClassName =
          index === imageIndex ? classNames(css.imagePreviewImage, css.selectedImage) : css.imagePreviewImage;

        return <img key={index} className={imageClassName} src={src} />;
      })}
    </ModalComponent>
  );
}

export default ImagePreview;
