import { Box } from '@mui/material';
import React from 'react';
import { compose } from 'redux';
import { injectIntl } from '../../util/reactIntl';
import css from './ListingPage.module.css';

import { Avatar, NamedLink, SecondaryButton } from '../../components';
import Spacer, { HORIZONTALL_AXIS } from '../../components/Spacer/Spacer';

function SectionOfferedBy(props) {
  const { authorLink, isOwnListing, intl, onContactUser, author, listing } = props;

  // const renderContactButton = () => (
  //   <Box display={{ xs: 'block', md: 'none' }}>
  //     <div className={css.authorWrapper}>
  //       <SecondaryButton rootClassName={css.contactSellerButton} onClick={() => onContactUser(author, listing)}>
  //         {intl.formatMessage({ id: 'listing_page.contact_seller' })}
  //       </SecondaryButton>
  //     </div>
  //   </Box>
  // );

  const renderLoginButton = () => (
    <div className={css.authorWrapper}>
      <NamedLink name="LoginPage" className={css.phoneButton}>
        {intl.formatMessage({ id: 'listing_page.offered_by.phone_number' })}
      </NamedLink>
    </div>
  );

  const renderContactButton = () => !isOwnListing ? (
    <SecondaryButton rootClassName={css.contactSellerButton} onClick={() => onContactUser(author, listing)}>
      {intl.formatMessage({ id: 'listing_page.contact_seller' })}
    </SecondaryButton>
  ) : null;

  return (
    <div className={css.sectionOfferedBy}>
      <div className={css.listingPageGroupLabelWrapper}>
        <label className={css.listingPageGroupLabel}>
          {intl.formatMessage({ id: 'listing_page.offered_by.label' })}
        </label>
      </div>
      <div className={css.authorWrapper}>
        <Avatar className={css.avatar} user={author} />
        <Spacer axis={HORIZONTALL_AXIS} size={8} />
        <span className={css.authorSpan}>
          {intl.formatMessage({ id: 'listing_page.sold_by' }, { name: authorLink })}
        </span>
      </div>
      <div className={css.contactSellerButtonWrapper}>
        {renderContactButton()}
      </div>
    </div>
  );
}

export default compose(injectIntl)(SectionOfferedBy);
