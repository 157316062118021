import React, { useState } from 'react';
import css from './Collapsible.module.css';

import DownArrowIcon from '../../assets/icons/arrows/down.png';
import UpArrowIcon from '../../assets/icons/arrows/up.png';

function Collapsible(props) {
  const { label, openByDefault, children } = props;

  const [open, setOpen] = useState(openByDefault || false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className={css.labelWrapper} onClick={toggle}>
        <label className={css.label}>{label}</label>
        <img className={css.arrowIcon} src={open ? UpArrowIcon : DownArrowIcon} />
      </div>
      {open && <div className={css.content}>{children}</div>}
    </>
  );
}

export default Collapsible;
