/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import css from './ListingPage.module.css';

import { Button } from '../../components';
import Spacer, { HORIZONTALL_AXIS } from '../../components/Spacer/Spacer';

import LikeBlankIcon from '../../assets/icons/hearts/blank-24.png';
import LikeFilledIcon from '../../assets/icons/hearts/filled-24.png';
import config from '../../config';

import AmexIcon from '../../assets/icons/payment-methods/amex.svg';
import ApplePayIcon from '../../assets/icons/payment-methods/apple-pay.svg';
import GooglePayIcon from '../../assets/icons/payment-methods/google-pay.svg';
import MaestroIcon from '../../assets/icons/payment-methods/maestro.svg';
import MastercardIcon from '../../assets/icons/payment-methods/mastercard.svg';
import PayPalIcon from '../../assets/icons/payment-methods/pay-pal.svg';
import VisaIcon from '../../assets/icons/payment-methods/visa.svg';

import MedalIcon from '../../assets/icons/listing-info-box/medal.png';
import ProtectedIcon from '../../assets/icons/listing-info-box/protected.png';
import ReturnsIcon from '../../assets/icons/listing-info-box/returns.png';
import SecurePaymentsIcon from '../../assets/icons/listing-info-box/secure-payments.png';
import ShippingIcon from '../../assets/icons/listing-info-box/shipping.png';

function SectionHeading({
  priceTitle,
  formattedPrice,
  richTitle,
  isOwnListing,
  onBuyNow,
  buyLoading,
  intl,
  listing,
  addUserFavorite,
  removeUserFavorite,
  isLiked,
  listingId,
  currentUserId,
  listingType,
  availableForPurchase,
  refurbished,
}) {
  const handleLikeClick = e => {
    e.stopPropagation();
    e.preventDefault();

    return isLiked ? removeUserFavorite(listingId) : addUserFavorite(listingId, listingType);
  };

  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = config.listingManagementType === 'stock' && currentStock === 0;

  const buyNowButton =
    !isOwnListing && availableForPurchase ? (
      <Button rootClassName={css.buyNowButton} onClick={onBuyNow} disabled={isOutOfStock} inProgress={buyLoading}>
        {intl.formatMessage({ id: 'listing_page.buy_now' })}
      </Button>
    ) : null;

  const likeButton =
    !isOwnListing && currentUserId ? (
      <Button rootClassName={css.likeListingButton} onClick={e => handleLikeClick(e)}>
        {isLiked ? (
          <img className={css.likeIcon} src={LikeFilledIcon} />
        ) : (
          <img className={css.likeIcon} src={LikeBlankIcon} />
        )}
        <Spacer axis={HORIZONTALL_AXIS} size={6} />
        {isLiked
          ? intl.formatMessage({ id: 'listing_page.remove_from_favorites' })
          : intl.formatMessage({ id: 'listing_page.add_to_favorites' })}
      </Button>
    ) : null;

  const infoBlock = {
    title: refurbished
      ? intl.formatMessage({ id: 'listing_page.refurbished_program.label' })
      : intl.formatMessage({ id: 'listing_page.buyer_protection_info.label' }),
    description: refurbished
      ? intl.formatMessage({ id: 'listing_page.refurbished_program.description' })
      : intl.formatMessage({ id: 'listing_page.buyer_protection_info.description' }),
    items: refurbished
      ? [
          {
            icon: MedalIcon,
            title: intl.formatMessage({ id: 'listing_page.refurbished_program.proposition.functional' }),
          },
          {
            icon: ProtectedIcon,
            title: intl.formatMessage({ id: 'listing_page.refurbished_program.proposition.warranty' }),
          },
          {
            icon: ReturnsIcon,
            title: intl.formatMessage({ id: 'listing_page.refurbished_program.proposition.returns' }),
          },
        ]
      : [
          {
            icon: ShippingIcon,
            title: intl.formatMessage({ id: 'listing_page.buyer_protection_info.proposition.shipping' }),
          },
          {
            icon: SecurePaymentsIcon,
            title: intl.formatMessage({ id: 'listing_page.buyer_protection_info.proposition.secure_payments' }),
          },
          {
            icon: ProtectedIcon,
            title: intl.formatMessage({ id: 'listing_page.buyer_protection_info.proposition.verified_sellers' }),
          },
          {
            icon: ReturnsIcon,
            title: intl.formatMessage({ id: 'listing_page.buyer_protection_info.proposition.returns' }),
          },
        ],
  };

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>{richTitle}</div>
      <div className={css.desktopPriceContainer}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
        </div>

        <div className={css.desktopHeadingActions}>
          {buyNowButton}
          {likeButton}
        </div>
        <div className={css.paymentMethodWrapper}>
          <img className={css.paymentMethodImage} src={ApplePayIcon} alt="Apple Pay" />
          <img className={css.paymentMethodImage} src={GooglePayIcon} alt="Google Pay" />
          <img className={css.paymentMethodImage} src={MastercardIcon} alt="Mastercard" />
          <img className={css.paymentMethodImage} src={MaestroIcon} alt="Mastercard" />
          <img className={css.paymentMethodImage} src={VisaIcon} alt="Visa" />
          <img className={css.paymentMethodImage} src={PayPalIcon} alt="Visa" />
          <img className={css.paymentMethodImage} src={AmexIcon} alt="Visa" />
        </div>
        <Spacer size={16} />
        <div className={css.infoBoxWrapper}>
          <label className={css.infoBoxLabel}>{infoBlock.title}</label>
          <p className={css.infoBoxDescription}>{infoBlock.description}</p>
          {infoBlock.items.map((item, idx) => (
            <div className={css.infoBoxPropositionWrapper} key={idx}>
              <img className={css.infoBoxPropositionLogo} src={item.icon} />
              <p className={css.infoBoxPropositionText}>{item.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SectionHeading;
